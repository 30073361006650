import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImageFluidProps } from 'gatsby-image';

import Layout from 'molecules/Layout';
import About from 'organisms/About';

const ABOUT_QUERY = graphql`
  query AboutQuery {
    logo: file(relativePath: { eq: "logos/thamarai-ueber-uns-logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 375, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image1: file(relativePath: { eq: "about/about-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, maxHeight: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "about/about-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768, maxHeight: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "about/about-5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, maxHeight: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

interface Data {
  logo: {
    childImageSharp: GatsbyImageFluidProps;
  };
  image1: { childImageSharp: GatsbyImageFluidProps };
  image2: { childImageSharp: GatsbyImageFluidProps };
  image3: { childImageSharp: GatsbyImageFluidProps };
}

interface Props {}

const AboutPage: React.FC<Props> = () => {
  const { logo, image1, image2, image3 } = useStaticQuery<Data>(ABOUT_QUERY);

  return (
    <Layout
      className="AboutPage"
      searchEngineOptimization={() => (
        <Layout.SEO
          path="/ueber-uns"
          canonical="/ueber-uns"
          title="Über uns"
          lang="de-DE"
        />
      )}
      footerIcon={() => <></>}
      header={() => (
        <Layout.Header
          title="Über uns"
          subtitle="Ratna Gowari und Rathika Soosaithasan"
          image={logo.childImageSharp}
          alt="Exklusives Logo für die 'Über Uns'-Seite"
        />
      )}
    >
      <About image1={image1} image2={image2} image3={image3} />
    </Layout>
  );
};

export default AboutPage;
