import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import { GatsbyImageFluidProps } from 'gatsby-image';

import AboutThilagamBanner from '../AboutThilagamBanner';
import styles from './About.module.scss';

function getAge(dob: string) {
  var today = new Date();
  var birthDate = new Date(dob);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age = age - 1;
  }

  return age;
}

const gowariAge = getAge('08/14/1961');

interface Props {
  image1: { childImageSharp: GatsbyImageFluidProps };
  image2: { childImageSharp: GatsbyImageFluidProps };
  image3: { childImageSharp: GatsbyImageFluidProps };
}

const About: React.FC<Props> = ({ image1, image2, image3 }) => {
  return (
    <>
      <div className={styles[`About`]}>
        <div className={`${styles[`About__box`]} ${styles[`About__box--1`]}`}>
          <div>
            Das <span className={styles[`About__strong`]}>Thamarai</span>{' '}
            Restaurant ist ein engagierter Familienbetrieb und Spezialist für
            die tamilische Küche. Unser Ziel ist es Sie mit exotisch-bunten
            Gerichten unserer Heimat zu verwöhnen. Wenn Ihnen das Thamarai-Logo
            bekannt vorkommt, dann sind wir uns vielleicht schon einmal
            begegnet, denn in den letzten drei Jahren waren wir damit auf
            zahlreichen Streetfood-Festivals der Region vertreten. Der große
            Anklang, den wir dort mit unseren Klassikern wie dem Kothu Roti oder
            dem Masala Dosai fanden, hat uns in unserem Wunsch bestärkt, endlich
            ein eigenes Restaurant zu eröffnen. Wir haben hart darauf
            hingearbeitet und freuen uns nun, Sie bald im Thamarai in Heilbronn
            begrüßen zu dürfen.
          </div>
        </div>
        <div className={`${styles[`About__box`]} ${styles[`About__box--2`]}`}>
          <div>
            Küchenchefin und Seele des Hauses ist Ratna{' '}
            <span
              className={`${styles[`About__entrepreneur`]} ${
                styles[`About__entrepreneur--gowari`]
              }`}
            >
              Gowari
            </span>{' '}
            Soosaithasan ({gowariAge}). Mit gerade einmal 22 Jahren kam sie aus
            ihrer Heimatstadt Jaffna im Norden Sri Lankas nach Deutschland. Es
            war damals nicht leicht für sie, sich in dem fremden Land
            zurechtzufinden. Doch schnell hat sie sich in ihrer neuen Umgebung
            eingelebt und Heilbronn als zweite Heimat lieben gelernt. Arbeit
            fand sie damals in einem koreanischen Restaurant in Wüstenrot, wo
            sie von einer einfachen Küchenhilfe zu einer gelernten Köchin
            avancierte. Als nach 12 Jahren die Chefin wieder in ihre Heimatstadt
            Soeul zurückkehrte, bot sich Gowari eine einmalige Chance: Man trug
            ihr an, das gut gehende Restaurant zu übernehmen.
          </div>
        </div>
        <div
          className={`${styles[`About__box`]} ${styles[`About__box--3`]} ${
            styles[`About__box--image`]
          }`}
        >
          <Img
            className={styles[`About__image`]}
            fluid={image1.childImageSharp.fluid}
            alt="Streetfood Festival Kreidetafel"
          />
          <div
            className={`${styles[`About__boxframe`]} ${
              styles[`About__boxframe--alternate`]
            }`}
          />
        </div>
        <div
          className={`${styles[`About__box`]} ${styles[`About__box--4`]} ${
            styles[`About__box--image`]
          }`}
        >
          <Img
            className={styles[`About__image`]}
            fluid={image2.childImageSharp.fluid}
            alt="Streetfood Festival Stand"
          />
          <div className={styles[`About__boxframe`]} />
        </div>
        <div className={`${styles[`About__box`]} ${styles[`About__box--5`]}`}>
          Die Verlockung war groß, der Zeitpunkt jedoch denkbar ungünstig. Ihre
          vier Kinder waren noch zu jung. Sie wollte genug Zeit für die Familie
          haben und dieser Anspruch war mit der Führung eines Restaurants dieser
          Größe nicht zu vereinbaren. So verzichtete sie auf ihren Traum. Ihren
          Lebensunterhalt verdiente sie fortan mit Jobs in der Metallindustrie,
          danach arbeitete sie mit Freude 10 Jahre lang als Hauswirtschafterin
          in einem Seniorenzentrum in Wüstenrot. Die Leidenschaft fürs Kochen
          hat sie dabei nie verloren - auch wenn sie inzwischen nicht mehr
          glaubte, dass der Wunsch vom eigenen Restaurant noch in Erfüllung
          gehen könnte.
        </div>
        <div className={`${styles[`About__box`]} ${styles[`About__box--6`]}`}>
          <div>
            Den Wendepunkt leitete ihre Tochter{' '}
            <span
              className={`${styles[`About__entrepreneur`]} ${
                styles[`About__entrepreneur--rathika`]
              }`}
            >
              Rathika
            </span>{' '}
            Soosaithasan ein. Sie studierte an der Universität Hohenheim
            Betriebswirtschaft, jobbte nebenbei als Kellnerin und entdeckte
            dabei ihre eigene Leidenschaft für die Gastronomie. Der Wunsch nach
            einem eigenen Restaurant festigte sich. Als ersten Schritt zu dessen
            Verwirklichung arbeitete sie nach dem Studium in verschiedenen
            Lokalen im Raum Heilbronn um wertvolle Erfahrungen zu sammeln.
            Gemeinsam mit Mutter Gowari trieb sie gleichzeitig die Pläne für das
            Thamarai voran, in dem sie nun die Geschäftsführung übernehmen wird.
          </div>
        </div>
        <div className={`${styles[`About__box`]} ${styles[`About__box--7`]}`}>
          Der Ursprung der tamilischen Küche erstreckt sich von Südindien bis in
          den Nordosten Sri Lankas. Dies sollte sich auch im Ambiente unseres
          Restaurants widerspiegeln und so begaben wir uns auf die Suche nach
          einer geeignetes Lokalität in Heilbronn. Da keines der angebotenen
          Objekte unseren Vorstellungen entsprach, beschlossen wir, das eigene
          Elternhaus entsprechend umzubauen. Es waren einige Hürden zu
          überwinden um die ehemalige Malermeisterwerkstatt Schritt für Schritt
          in das heutige Thamarai zu verwandeln.
        </div>
        <div className={`${styles[`About__box`]} ${styles[`About__box--8`]}`}>
          Dekor und Einrichtung haben wir zu einem großen Teil aus Indien
          mitgebracht. Den Gastraum haben wir - in Anlehnung an den britischen
          Kolonialstil - mit dunkelbraunen Tischen und Stühlen ausgestattet und
          mit echt indischen Holzschnitzerein verziert. Neben der Lotusblume,
          Elefanten und weiteren kulturellen Elementen werden Sie dort auch den
          Hindu-Gott Ganesha entdecken.
        </div>
        <div className={`${styles[`About__box`]} ${styles[`About__box--9`]}`}>
          Zahlreiche Gäste, die uns von Streetfood-Festivals kennen, warten
          schon ungeduldig auf die Eröffnung des Thamarai, um wieder in den
          Genuss unserer tamilischen Köstlichkeiten zu kommen. Auch wir selbst
          sind glücklich, dem Gastronomie-Angebot der Region bald noch eine neue
          Facette hinzufügen zu können. Mutter Gowari möchte sich in unser aller
          Namen ganz herzlich bei den Menschen bedanken, die uns auf unserem Weg
          begleitet und unser Projekt unterstützt haben.
        </div>
        <div
          className={`${styles[`About__box`]} ${styles[`About__box--10`]} ${
            styles[`About__box--image`]
          }`}
        >
          <Img
            className={styles[`About__image`]}
            fluid={image3.childImageSharp.fluid}
            alt="Tamko - Spezialität des Hauses"
            objectFit="cover"
            objectPosition="50% 10%"
          />
          <div className={styles[`About__boxframe`]} />
        </div>
      </div>
      <AboutThilagamBanner />
    </>
  );
};

export default About;
