import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img, { GatsbyImageFluidProps } from 'gatsby-image';
import Button, { ButtonSize, ButtonTheme } from '@soosap/sushi/Button';
import styles from './AboutThilagamBanner.module.scss';

const ABOUT_THILAGAM_BANNER_QUERY = graphql`
  query AboutThilagamBanner {
    portrait: file(relativePath: { eq: "about/about-thilagam-portrait.png" }) {
      childImageSharp {
        fluid(maxWidth: 1224) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

interface Data {
  portrait: {
    childImageSharp: GatsbyImageFluidProps;
  };
}

export interface Props {}

const AboutThilagamBanner: React.FC<Props> = () => {
  const { portrait } = useStaticQuery<Data>(ABOUT_THILAGAM_BANNER_QUERY);

  return (
    <Link to="/ueber-uns/thilagam">
      <div className={styles[`AboutThilagamBanner`]}>
        <div className={styles[`AboutThilagamBanner__inner`]}>
          <div className={styles[`AboutThilagamBanner__content`]}>
            <div className={styles[`AboutThilagamBanner__title`]}>
              In Andenken an
            </div>
            <div className={styles[`AboutThilagamBanner__subtitle`]}>
              <Button theme={ButtonTheme.DARK} size={ButtonSize.MASSIVE}>
                THILAGAM
              </Button>
            </div>
            <div className={styles[`AboutThilagamBanner__fromTo`]}>
              1951 - 2020
            </div>
            <div className={styles[`AboutThilagamBanner__role`]}>
              - Mitgründer -
            </div>
          </div>
          <div className={styles[`AboutThilagamBanner__portrait`]}>
            <Img
              fluid={portrait.childImageSharp.fluid}
              alt="Thilagam Soosaithasan Portrait"
            />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default AboutThilagamBanner;
